<template>
  <main-document :tabs="tabs"> </main-document>
</template>

<script>

import globalActions from '@/mixins/global-actions'
import tabController from '@/tabController'

export default {
  mixins: [globalActions],
  components: {
    MainDocument: () => import('@/components/main-document')
  },
  activated () {
    this.$store.commit('setModule', {
      name: this.$t('t.BusinessDivision'),
      icon: 'i.BusinessDivisions'
    })
  },
  created () {
  },
  deactivated () {
    this.$removeFromKeepAliveCache()
    this.$destroy()
  },
  computed: {
    tabs () {
      const tabs = [
        tabController.create(tabController.businessDivisionTab(), {
          title: this.$t('t.Infos'),
          icon: '',
          documentId: this.$route.params.id,
          canClose: false
        }),
        {
          documentType: 'hierarchy',
          title: this.$t('t.Hierarchy'),
          canClose: false
        }
        // {
        //   documentType: 'email',
        //   title: this.$t('t.EmailSettings'),
        //   documentId: this.$route.params.id,
        //   canClose: false
        // }
      ]

      return tabs
    }
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
